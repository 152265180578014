import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { graphql, Link, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import RegistrationThanksBanner from '../components/registration-thanks-banner';
import { selectEntityUserDetails } from '../store/selectors/entities';
import { getInstanceName, isClient } from '../helpers/utils';

import styles from '../styles/pages/registration-success.module.scss';

const query = graphql`
  query {
    bgLeft: file(relativePath: { eq: "confeti-left.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgRight: file(relativePath: { eq: "confeti-right.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const stateSelector = createStructuredSelector({ userDetails: selectEntityUserDetails });

const RegistrationSuccessPage = () => {
  const intl = useIntl();
  const data = useStaticQuery(query);
  const { userDetails } = useSelector(stateSelector);
  const username = isClient && localStorage.getItem('UN');
  const fullName = userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : '';
  const email = userDetails ? userDetails.email : '';
  const id = userDetails && userDetails.customerId;
  const fullNameText = intl.formatMessage({ id: 'registrationSuccess.fullName' });
  const usernameText = intl.formatMessage({ id: 'registrationSuccess.username' });
  const emailText = intl.formatMessage({ id: 'registrationSuccess.email' });
  const idText = intl.formatMessage({ id: 'registrationSuccess.id' });
  const toComplete = intl.formatMessage({ id: 'registrationSuccess.toComplete' });
  const inCaseYour = intl.formatMessage({ id: 'registrationSuccess.inCaseYour' });
  const proceedToPayment = intl.formatMessage({ id: 'registrationSuccess.proceedToPayment' });
  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.registrationSuccess' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className={styles.banner}>
          <RegistrationThanksBanner userName={username} />
        </div>

        <div className={styles.relative}>
          <BackgroundImage
            Tag="div"
            className={styles.summary_bg_overlay}
            fluid={[data.bgLeft.childImageSharp.fluid, data.bgRight.childImageSharp.fluid]}
            backgroundColor="transparent"
          />
          <>
            <div className={styles.wrapper}>
              <div className={styles.content}>
                <div className={styles.row}>
                  <p>{fullNameText}</p>
                  <p>{fullName}</p>
                </div>
                <div className={styles.row}>
                  <p>{usernameText}</p>
                  <p>{username}</p>
                </div>
                <div className={styles.row}>
                  <p>{emailText}</p>
                  <p>{email}</p>
                </div>
                <div className={styles.row}>
                  <p>{idText}</p>
                  <p>{id}</p>
                </div>

                <div className={styles.divider} />

                <div className={styles.smallText}>{toComplete}</div>
              </div>

              <div className={styles.footer}>
                {inCaseYour}
                <a href="https://help.livesupportnow.com" className={styles.referalLink}>
                  help.livesupportnow.com
                </a>
              </div>
            </div>
          </>
        </div>

        <div className={styles.action}>
          <Link to="/simple-checkout">
            <Button className={styles.button} size="large" withArrow>
              {proceedToPayment}
            </Button>
          </Link>
        </div>
      </Layout>
    </>
  );
};

export default RegistrationSuccessPage;
